import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseInitialize = firebase.initializeApp({
    apiKey: "AIzaSyB3JdRZE3BElPFlftX4-nMNaA8-Y2XrFWI",
    authDomain: "bug--tracker---developer.firebaseapp.com",
    databaseURL: "https://bug--tracker---developer-default-rtdb.firebaseio.com",
    projectId: "bug--tracker---developer",
    storageBucket: "bug--tracker---developer.appspot.com",
    messagingSenderId: "91023141128",
})

export const fireBaseAuthorize = firebaseInitialize.auth();
export const fireBaseDatabase = firebaseInitialize.database();

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export default firebaseInitialize;
